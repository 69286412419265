@import "abstracts/_abstracts";
.CheckboxBase {
	font-size: 1em;
	margin: 0;
	width: 1em;
	height: 1em;
	border: 2px solid var(--colorText);
	position: relative;
	transition: var(--animationBase);
	cursor: pointer;
	outline: none;
	border-radius: 0;

	&:not(:disabled):hover, &:not(:disabled):focus, &:checked {
		background: var(--colorBrand);
	}

	&:checked {
		border-color: var(--colorBrand);
	}

	&:after {
		content: ' ';
		display: block;
		width: .62em;
		height: .34em;
		border-left: 0.18em solid text-contrast(var(--colorBrand));
		border-bottom: 0.18em solid text-contrast(var(--colorBrand));
		transform: rotate(-45deg);
		margin-left: calc(1em * 0.19 - 1px);
		margin-top: calc(1em * 0.22 - 1px);
		transition: var(--animationBase);
		opacity: 0;
	}

	&:checked:after {
		opacity: 1;
	}

	&:disabled, &:disabled + * {
		border-color: var(--colorTextSubtle);
		opacity: .4;
	}

	&.is-invalid {
		border-color: var(--colorErrorsDefault);
	}
}